import { createSlice } from '@reduxjs/toolkit';
import themeConfig from '../theme.config';

const initialState = {
    isLoggedIn: false,
    data: false,
    role: 'guest',
    token: null,
};

const userSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        login(state, { payload }) {
            state.data = payload.user;
            state.role = payload.user.role;
            state.token = payload.token;
            state.isLoggedIn = true
        },
        
        setPageTitle(state, { payload }) {
            document.title = `${payload} | CESTRAR - Member MIS`;
        },
    },
});

export const { login } = userSlice.actions;

export default userSlice.reducer;
