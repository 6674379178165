import axios from 'axios'

axios.defaults.baseURL = process.env.NEXT_PUBLIC_API_HOST + '/api/'

if (typeof window !== 'undefined') {
    const token = localStorage.getItem('token')
    axios.defaults.headers['Authorization'] = "Bearer " + token
}


export default axios